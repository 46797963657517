
import { defineComponent, ref, onMounted, onBeforeMount } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import $ from "jquery";
import "select2/dist/css/select2.css";
import "select2";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "admin-create-vehicle-model-component",
  components: { Form, Field, ErrorMessage },
  props: {
    vehicles: Array,
  },
  setup(_, { emit }) {
    const { capitalize, generateUUID } = ResusableFunctions();
    const submitButton1 = ref<HTMLElement | null>(null);
    const currentYear = new Date().getFullYear();
    const payload = ref({
      brandName: "",
      models: [
        {
          id: generateUUID(),
          type: "",
          name: "",
          startYear: "",
          endYear: "",
        },
      ],
    });

    const validator = Yup.object().shape({
      brandName: Yup.string()
        .required()
        .label("Vehicle brand"),
      models: Yup.array()
        .of(
          Yup.object().shape({
            type: Yup.string()
              .required()
              .label("Vehicle model type"),
            name: Yup.string()
              .required()
              .label("Vehicle model name"),
            startYear: Yup.number()
              .required()
              .min(1900)
              .max(currentYear)
              .label("Vehicle start year"),
            endYear: Yup.number()
              .required()
              .min(1900)
              .max(currentYear)
              .label("Vehicle end year"),
          })
        )
        .required("At least one model is required")
        .min(1, "At least one model is required"),
    });

    const addModels = () => {
      payload.value.models.push({
        id: generateUUID(),
        type: "",
        name: "",
        startYear: "",
        endYear: "",
      });
    };

    const removeModel = (id: string) => {
      if (payload.value.models.length > 1) {
        const element = document.getElementById(id);
        if (element) {
          element.remove();
        }
      }
    };

    const submit = () => {
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");
          const formData = payload.value.models.map(({ id, ...rest }) => rest);

          // Send form data
          if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.post(
              `${variables.CARS_ROUTE}/${payload.value.brandName}/model`,
              { models: formData }
            )
              .then((response: any) => {
                variables.toastAlert(response.data.data.message, "success");
                emit("resetVehicleForms");
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    const getVehicleModels = (value: any) => {
      payload.value.brandName = value.target.value;
    };

    onBeforeMount(() => {
      $("#vehicle-brand").select2("destroy");
    });

    onMounted(() => {
      const $vehicleBrand = $("#vehicle-brand");
      $vehicleBrand.select2();
      $vehicleBrand.on("change", getVehicleModels);
    });

    return {
      capitalize,
      addModels,
      removeModel,
      getVehicleModels,
      submit,
      validator,
      submitButton1,
      payload,
    };
  },
});
