
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { Field } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import ResusableFunctions from "@/composables/ReusableFunctions";
import CreateVehicleBrand from "@/components/admin/vehicles/CreateVehicleBrand.vue";
import CreateVehicleModel from "@/components/admin/vehicles/CreateVehicleModel.vue";
import CreateVehicleYear from "@/components/admin/vehicles/CreateVehicleYear.vue";

export default defineComponent({
  name: "admin-vehicles",
  components: {
    Field,
    CreateVehicleBrand,
    CreateVehicleModel,
    CreateVehicleYear,
  },
  data() {
    return {
      displayVehicleTable: true,
      displayVehicleBrand: false,
      displayVehicleModel: false,
      displayVehicleYear: false,
    };
  },
  setup() {
    const {
      isEmptyArray,
      countArrayItems,
      displayDatatable,
    } = ResusableFunctions();
    const vehicles = ref();

    // Function to get underwriters
    const getVehicles = async () => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.CARS_ROUTE)
          .then(({ data }) => {
            //Assign data to form fields
            vehicles.value = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    };

    onMounted(() => {
      setCurrentPageTitle("Vehicles");
      getVehicles();

      setTimeout(() => {
        if (!isEmptyArray(vehicles.value)) {
          displayDatatable(["basicExample"]);
        }
      }, 5000);
    });

    return { countArrayItems, getVehicles, vehicles };
  },
  created() {
    //Set page title
    document.title = "Vehicles | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    createVehicleBrand() {
      this.displayVehicleTable = false;
      this.displayVehicleModel = false;
      this.displayVehicleYear = false;
      this.displayVehicleBrand = true;
    },
    createVehicleModel() {
      this.displayVehicleTable = false;
      this.displayVehicleBrand = false;
      this.displayVehicleYear = false;
      this.displayVehicleModel = true;
    },
    createVehicleYear() {
      this.displayVehicleTable = false;
      this.displayVehicleBrand = false;
      this.displayVehicleModel = false;
      this.displayVehicleYear = true;
    },
    resetVehicleForms() {
      this.displayVehicleTable = true;
      this.displayVehicleModel = false;
      this.displayVehicleYear = false;
      this.displayVehicleBrand = false;
      this.getVehicles();
    },
  },
});
