
import { defineComponent, ref, onMounted, onBeforeMount } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import $ from "jquery";
import "select2/dist/css/select2.css";
import "select2";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "admin-create-vehicle-year-component",
  components: { Form, Field, ErrorMessage },
  props: {
    vehicles: Array,
  },
  setup(_, { emit }) {
    const { capitalize } = ResusableFunctions();
    const submitButton1 = ref<HTMLElement | null>(null);
    const models = ref();
    const payload = ref({
      brandName: "",
      modelName: "",
      year: "",
    });

    const validator = Yup.object().shape({
      brandName: Yup.mixed()
        .required()
        .label("Vehicle brand"),
      modelName: Yup.string()
        .required()
        .label("Vehicle model"),
      year: Yup.number()
        .required("Only numbers are allowed.")
        .integer("Vehicle year must be an integer.")
        .positive("Vehicle year must be a positive number.")
        .label("Vehicle year"),
    });

    const submit = () => {
      alert();
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Send form data
          if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.post(variables.LEGACY_POLICY_UPLOAD, payload.value)
              .then((response) => {
                variables.toastAlert(response.data.data.message, "success");
                emit("resetVehicleForms");
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    const getVehicleModels = (value) => {
      payload.value.brandName = value.target.value;
      const modelsData =
        value.target.options[value.target.selectedIndex].dataset.models;
      models.value = JSON.parse(modelsData);
    };

    const getVehicleModel = (value) => {
      payload.value.modelName = value.target.value;
    };

    onBeforeMount(() => {
      $("#vehicle-brand").select2("destroy");
      $("#vehicle-model").select2("destroy");
    });

    onMounted(() => {
      const $vehicleBrand = $("#vehicle-brand");
      $vehicleBrand.select2();
      $vehicleBrand.on("change", getVehicleModels);

      const $vehicleModel = $("#vehicle-model");
      $vehicleModel.select2();
      $vehicleModel.on("change", getVehicleModel);
    });

    return {
      capitalize,
      getVehicleModels,
      getVehicleModel,
      submit,
      validator,
      submitButton1,
      payload,
      models,
    };
  },
});
