
import { defineComponent, ref } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";

export default defineComponent({
  name: "admin-create-vehicle-brand-component",
  components: { Form, Field, ErrorMessage },
  props: {
    widgetClasses: String,
  },
  setup(_, { emit }) {
    const submitButton1 = ref<HTMLElement | null>(null);
    const name = ref("");

    const validator = Yup.object().shape({
      name: Yup.string()
        .required()
        .label("Vehicle brand name"),
    });

    const submit = () => {
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Send form data
          if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.post(variables.CARS_ROUTE, {
              name: name.value,
            })
              .then((response) => {
                variables.toastAlert(response.data.data.message, "success");
                emit("resetVehicleForms");
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    return { validator, submit, name, submitButton1 };
  },
});
